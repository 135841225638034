<template>
  <div>
    <b-input-group>
      <b-form-input
        class="search-input"
        :placeholder="placeholder"
        :value="value"
        @input="(v) => $emit('input', v)"
        @keyup.enter="handleSearch"
      ></b-form-input>
      <b-input-group-append>
        <b-button :disabled="isBusy" class="search-button" @click="handleSearch">
          <b-spinner v-if="isBusy" class="spinner-save" />
          <component v-else :is="'search-icon'" class="iconSearch"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import GenericApi from '@/services/genericRequest';

export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'getWithoutPagination';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    route: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    wantsClean: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
    };
  },

  methods: {
    async handleSearch() {
      this.isBusy = true;

      this.$emit('isBusy', true);
      try {
        const { filters } = this;

        const result = await this.api[this.apiFunction](
          { filters },
          this.route,
        );

        this.update(result);
        this.isBusy = false;
      } catch (error) {
        this.$emit('isBusy', false);
        this.isBusy = false;
        let errorMessage = 'Ocorreu um problema ao recuperar o objeto lido';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          icon: 'error',
          text: errorMessage,
          buttons: {
            confirm: 'OK',
          },
        });
      }

      this.isBusy = false;
      this.$emit('isBusy', false);
      if (this.wantsClean) {
        this.$emit('input', null);
      }
    },

    update(v) {
      this.$emit('update', v);
    },
  },
};
</script>

<style scoped>
.search-button {
  margin: 0;
  border: 1px solid #ced4da;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  height: 40px;
  width: 4em;
}

.search-input {
  border-right: none;
  text-overflow: ellipsis;
}

.spinner-save {
  position: relative;
  color: white;
  width: 1.2rem;
  height: 1.2rem;
}

.iconSearch {
  position: relative;
  fill: white !important;
}
</style>
